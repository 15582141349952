.editorWrapper {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #a0a0a0;
}

.editorContent {
  padding: 0 16px; /* Adjust the padding as needed */
  min-height: 13rem;
  max-height: 16rem;
}
.editorContent h1 {
  font-size: 2rem;
}
