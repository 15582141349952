.settingsEditorWrapper {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #cecece;
  min-height: 14rem;
}

.settingsEditorContent {
  padding: 0 16px;
  /* Adjust the padding as needed */
  min-height: 8rem;
  height: 100%;
}

.settingsEditorContent h1 {
  font-size: 2rem;
}